"use client";
import swrConfig from "@/config/swr";
import { SessionProvider } from "next-auth/react";
import { Suspense, useEffect } from "react";
import { SWRConfig } from "swr";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import { GlobalProvider } from "./global";
import { NotificationsProvider } from "./notifications";

import { QueryClient, QueryClientProvider } from "react-query";
import Loading from "@/app/loading";

const queryClient = new QueryClient();

export default function Context(props: {
  siteSettings?: any;
  appVersion: any;
  children?: any;
  env: any;
  enabledFeatures: {
    name: string;
    active: boolean;
    type: string | null;
    otherData: any
  }[]
}) {
  const swrOptions = swrConfig();

  useEffect(() => {
    // @ts-ignore
    globalThis.env = {}
    for (const v in props.env) {
      process.env[v] = props.env[v];
      // @ts-ignore
      globalThis.env[v] = props.env[v];
    }

    if (
      process.env.NODE_ENV === "production" &&
      process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
    ) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <GlobalProvider
          siteSettings={props.siteSettings}
          appVersion={props.appVersion}
          enabledFeatures={props.enabledFeatures}
        >
          <SessionProvider>
            <SWRConfig value={swrOptions}>
              <Toaster
                position="bottom-center"
                toastOptions={{ duration: 10000 }}
              />
              <NotificationsProvider>
                {props.children}
              </NotificationsProvider>
            </SWRConfig>
          </SessionProvider>
        </GlobalProvider>
      </QueryClientProvider>
    </Suspense>
  );
}
