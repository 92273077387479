"use client";

import { useThemeStorage } from "@/lib/theme/usetheme";
import { useEffect, useState } from "react";

const timeForExcess = 10 * 1000;

export default function LoadingOverpage(props: {
  children?: React.ReactNode;
  loading?: boolean;
  description?: string;
}) {

  const { theme } = useThemeStorage();
  const [loadingTimeExceeded, setLoadingTimeExceeded] = useState(false);

  const show = props.loading === undefined ? true : props.loading;

  useEffect(() => {
    const e = setTimeout(() => {
      setLoadingTimeExceeded(true);
    }, timeForExcess);

    return () => {
      clearTimeout(e)
    }
  }, [])

  return (
    <div className={`${theme}`}>
      <div className={`fixed inset-0 z-40 flex items-center justify-center bg-background/80 backdrop-blur-sm transition-all ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'
        } `}>
        <div className="text-center">
          <div className="inline-block animate-spin rounded-full border-t-4 border-primary border-r-4 border-r-transparent h-12 w-12 mb-4"></div>
          <h2 className="text-2xl font-bold mb-2">{props.children}</h2>
          {props.description && (
            <p className="text-muted-foreground">{props.description}</p>
          )}
          {loadingTimeExceeded && (
            <div className="mt-4">
              Did it take too long?{" "}
              <a
                onClick={() => window.location.reload()}
                href="#"
                className="text-primary hover:underline"
              >
                Reload
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
